<template>
  <div>
    <BaseCard
      header-caption="Editar pedido"
      :footer-visible="false"
      :loading="loading"
    >
      <OrderForm
        ref="order-form"
        v-model="orderModel"
      />
    </BaseCard>
    <OrderLinesTable />
    <b-row
      align-v="center"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
          :disabled="loading"
          @click="handleSaveButtonClick"
        >
          Guardar Pedido
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrdersApi from '@/api/orders-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import OrderForm from '@/components/orders/form/OrderForm.vue'
import OrderLinesTable from '@/components/orders/table/OrderLinesTable.vue'
import PurchaseInvoicesApi from '@/api/purchase-invoices-api'

export default {
  components: { OrderLinesTable, OrderForm, BaseCard },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('orders', {
      order: 'getOrder',
    }),
    orderModel: {
      get() {
        return this.order
      },
      set(value) {
        this.setOrder(value)
      },
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('orders', ['loadOrder', 'setOrder']),
    async loadData() {
      this.loading = true
      try {
        await this.loadOrder(this.$route.params.id)
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const valid = await this.$refs['order-form'].validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        const { documentation, removedFiles } = this.$refs['order-form'].getDocumentationData()
        const order = { ...this.order, documentation }
        delete order.id
        await Promise.all([
          OrdersApi.edit(this.order.id, order),
          OrdersApi.deleteFiles(this.order.id, removedFiles),
        ])
        await this.checkOrderPuchaseInvoices(order)
        await this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
    async checkOrderPuchaseInvoices(order) {
      const response = await PurchaseInvoicesApi.list({ page: 0, per_page: 0, filters: { order_id: this.order.id } })
      const totalPurchaseInvoices = response.meta.total[1]
      if (order.status?.name.toLowerCase() === 'facturado' && totalPurchaseInvoices === 0) {
        this.$toast.warning('Pedido facturado sin facturas de proveedor asociadas.')
      }
    },
  },
}
</script>

<style scoped>
</style>
